import {Vue, Component, Watch} from "vue-property-decorator";
import DfBrand from "@/vue/domain/brand/df-brand";
import Store from "@/vue/infrastructure/store/store";
import DfStore from "@/vue/domain/store/df-store";

@Component
export default class DfPageErrorComponent extends Vue {
  private errorCode: string = null;

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get errorDescription(): string {
    let errorDescription = "";
    switch (this.errorCode) {
      case "403":
        errorDescription = this.$t("df-page-error.error-type.403").toString();
        break;
      case "404":
        errorDescription = this.$t("df-page-error.error-type.404").toString();
        break;
      case "500":
        errorDescription = this.$t("df-page-error.error-type.500").toString();
        break;
    }
    return errorDescription;
  }

  @Watch("$route.params.errorCode", { immediate: true })
  private errorCodeChange() {
    document.title = `${this.$t("df-page-error.title", { 0: this.errorCode }).toString()} | ${this.brand.description}`;
  }

  beforeMount() {
    this.errorCode = this.$route.params.errorCode.toString();
  }
}
